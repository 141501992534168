<template>
  <component
    :is="node"
    class="button button-elem"
    :class="[iconClass, spinnerClass]"
    @click="$emit('click')">
      <slot></slot>
      <spinner-elem v-if="spinner"></spinner-elem>
  </component>
</template>

<script>
export default {
  name: 'button-elem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    node: {
      type: String,
      default: 'button'
    },
    spinner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      if (fn.isString(this.icon)) {
        return 'icon-' + this.icon
      }
      return null
    },
    spinnerClass () {
      if (this.spinner) {
        return 'is-disabled has-spinner'
      }
      return null
    }
  }
}
</script>

<style lang="sass">
.button-elem
  position: relative
  &.move-down
    &:before
      padding-top: .2em
  &.move-up
    &:before
      padding-bottom: .2em
  &.move-left
    &:before
      padding-right: .3em
  &.move-right
    &:before
      padding-left: .3em
  &.has-spinner
    color: transparent !important
    &:before
      color: transparent !important
    .spinner-elem
      display: inline-block
      position: absolute
      left: 50%
      top: 50%
      width: 60px
      margin-left: -30px
      margin-top: -10px
      animation-delay: .2s
      div
        background-color: $grey
</style>