var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{ref:"footer",staticClass:"segment footer-segment"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"row main"},[_c('div',{staticClass:"col is-4 contact"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('text-elem',{staticClass:"address",attrs:{"text":[
                _vm.$store.state.site.content.company,
                _vm.$store.state.site.content.address
              ]}})],1),_c('div',{staticClass:"col"},[_c('text-elem',{staticClass:"phone",attrs:{"text":[
                'Tel: ' + _vm.$store.state.site.content.telephone.value,
                'Fax: ' + _vm.$store.state.site.content.fax.value
              ]}}),_c('link-elem',{attrs:{"link":_vm.$store.state.site.content.email}})],1),_c('div',{staticClass:"col"},[_c('text-elem',{staticClass:"info",attrs:{"text":[
                _vm.$store.state.site.content.contactinfo
              ]}})],1)])]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col is-8 map"},[_c('div',{attrs:{"id":"map"}})])}]

export { render, staticRenderFns }