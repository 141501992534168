<template>
  <a
    :href="href"
    :target="target"
    @click="onClick($event)"
    class="link">
      <slot>{{ linkConverted.title }}</slot>
  </a>
</template>

<script>
import dom from '@/services/DOM'
import scroll from '@/services/Scroll'

export default {
  name: 'link-elem',
  props: {
    link: {
      type: Object
    }
  },
  computed: {
    linkConverted () {
      // @see comment at dom.linkHelberTemp
      return dom.linkHelperTemp(this.link)
    },
    href () {
      switch (this.linkConverted.type) {
        case 'page':
          return this.pageUri
        case 'extern':
        case 'email':
        case 'file':
          return this.linkUrl
      }
      return null
    },
    target () {
      switch (this.linkConverted.type) {
        case 'extern':
        case 'file':
          return '_blank'
      }
      return null
    },
    pageUri () {
      if (fn.has(this.linkConverted, 'redirect')) {
        return this.linkConverted.redirect
      } else if (fn.has(this.linkConverted, 'uri')) {
        return this.linkConverted.uri
      }
      return null
    },
    linkUrl () {
      if (fn.has(this.linkConverted, 'url')) {
        return this.linkConverted.url
      }
      return null
    }
  },
  methods: {
    scrollTo (anchor) {
      if (fn.has(this.$store.state.elements, anchor)) {
        scroll.smooth(this.$store.state.elements[anchor])
      }
    },
    onClick (Event) {
      Event.stopPropagation()
      switch (this.linkConverted.type) {
        case 'page':
          Event.preventDefault()
          this.$router.push(this.pageUri)
          break;
        case 'scroll':
          this.scrollTo(this.linkConverted.anchor)
          break;
      }
      this.$emit('onclick', Event, this.linkConverted)
    }
  }
}
</script>

<style lang="sass">
.link-elem
  cursor: pointer
</style>