<template>
  <ul
    :class="elemClass()"
    class="links-elem"
    ref="links">
      <li
        v-for="link in linksConverted"
        :key="link.id"
        :class="itemClass(link)"
        @mouseenter="onMouseEnter($event, link)"
        @mouseleave="onMouseLeave($event, link)"
        class="item">
          <link-elem
            :link="link"
            @onclick="onClick($event, link)"
            :class="linkClass">
              {{ link.title }}
          </link-elem>
          <links-elem
            v-if="hasChildren(link)"
            :links="link.children"
            :maxLevel="maxLevel"
            :currentLevel="currentLevel + 1"
            :dropdownStatus="dropdownStatus"
            :class="{ 'is-left': dropdownPosition === 'left', 'is-right': dropdownPosition === 'right' }"
            @onmouseenter="onMouseEnter"
            @onmouseleave="onMouseLeave"
            @onclick="onClick">
          </links-elem>
      </li>
  </ul>
</template>

<script>
import dom from '@/services/DOM'

export default {
  name: 'links-elem',
  props: {
    links: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxLevel: {
      type: Number,
      default: 1
    },
    currentLevel: {
      type: Number,
      default: 1
    },
    dropdownStatus: {
      type: Object,
      default () {
        return {}
      }
    },
    dropdownPosition : {
      type: String,
      default: 'left'
    },
    linkClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    linksConverted () { // @see comment at dom.linksHelperTemp
      return dom.linksHelperTemp(this.links)
    },
  },
  methods: {
    isPage (link) {
      return link.type === 'page'
    },
    isGroup (link) {
      return link.type === 'group'
    },
    hasChildren (link) {
      return this.isGroup(link) && this.currentLevel < this.maxLevel && link.children && link.children.length > 0
    },
    elemClass () {
      return 'links-elem-level-' + this.currentLevel
    },
    itemClass (link) {
      var res =  ['is-' + link.type]
      if (link.home) {
        res.push('is-home')
      }
      if ((this.isPage(link) || this.isGroup(link)) && fn.has(link, 'uri')) {
        res = res.concat(dom.getActiveClasses(this.$route.path, link.uri))
      }
      if (this.hasChildren(link)) {
        res.push('has-dropdown')
      }
      if (this.isGroup(link) && this.dropdownStatus[link.id]) {
        res.push('is-open')
      }
      if (fn.has(link, 'cssclass') && fn.isString(link.cssclass)) {
        res.push(link.cssclass)
      }
      return res.join(' ')
    },
    getWidth () { // used by parent
      return dom.getWidth(this.$refs.links)
    },
    onMouseEnter (Event, link) {
      Event.stopPropagation()
      this.$emit('onmouseenter', Event, link)
    },
    onMouseLeave (Event, link) {
      Event.stopPropagation()
      this.$emit('onmouseleave', Event, link)
    },
    onClick (Event, link) {
      this.$emit('onclick', Event, link)
    }
  }
}
</script>

<style lang="sass">
// Minimum style for classes is-vertical and is-horizontal
// usually styled by parent component
.links-elem
  &.is-vertical
    .links-elem-level-2
      .item
        margin-left: m(3)
  &.is-horizontal
    display: flex
    .item
      margin-right: m(1)
      &:last-child
        margin-right: 0
    .links-elem-level-2
      display: none
</style>