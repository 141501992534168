<template>
  <div class="sections-elem" :class="css">
    <template v-for="(section, i) in sections">

      <!-- Headlines, Copy -->
      <div
        v-if="isText(section)"
        :key="key(i)"
        class="section copy row">
          <text-elem
            :text="section.text"
            class="col is-10 is-12-sm is-offset-1 is-no-offset-sm text">
          </text-elem>
      </div>

      <!-- Images -->
      <div
        v-if="isImages(section)"
        :key="key(i)"
        :class="imagesClass(section)"
        class="section images row is-box">
          <div class="col">
            <images-elem
              :files="section.images.value"
              :size="imagesSize(section)"
              :display="section.display.value"
              :zoom="section.zoom.value"
              :autoplay="section.autoplay.value"
              :ranges="section.ranges.value"
              :speed="section.speed.value"
              :oneRowXS="section.onerowmobile.value"
              :pagination="true"
              :title="subheadline(section)"
              paginationPosition="inside">
            </images-elem>
          </div>
      </div>

      <!-- Boxes -->
      <div
        v-else-if="isBoxes(section)"
        :key="key(i)"
        class="section boxes row is-box">
          <boxes-elem
            :boxes="section.boxes"
            class="col is-small-text">
          </boxes-elem>
      </div>

      <!-- Links -->
      <div
        v-else-if="isLinks(section)"
        :key="key(i)"
        class="section links row">
          <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm">
             <links-elem
              :links="section.links"
              :maxLevel="1"
              linkClass="button is-medium is-outline">
            </links-elem>
          </div>
       </div>

      <!-- Video -->
      <div
        v-else-if="isVideo(section)"
        :key="key(i)"
        :class="sectionClass(section)"
        class="section video row text">
          <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm">
            <h2 v-if="subheadline(section)">{{ subheadline(section) }}</h2>
            <video-elem
              :source="section.source.value"
              :video="section.video.value">
            </video-elem>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'sections-elem',
  props: ['css', 'content'],
  data () {
    return {}
  },
  computed: {
    sections () {
      var res = []
      var boxKey = null
      var textKey = null
      var linkKey = null
      fn.each(this.content, (section, key) => {

        // put boxes with property group = true in one array, but never more than 3 together
        if (section.fieldset === 'box') {
          if (section.group.value && boxKey !== null && res[boxKey].boxes.length < 3) {
            res[boxKey].boxes.push(section)
          } else {
            res.push({
              fieldset: 'boxes',
              boxes: [ section ]
            })
            boxKey = res.length - 1
          }
          textKey = null
          linkKey = null
        }
        
        // put h1 (h2) and copy together
        else if (this.isHeadline(section) || this.isCopy(section)) {
          if (textKey !== null) {
            res[textKey].text.push(section)
          } else {
            res.push({
              fieldset: 'text',
              text: [ section ]
            })
            textKey = res.length - 1
          }
          boxKey = null
          linkKey = null
        }

        // put link to gether
        else if (this.isLink(section)) {
          if (linkKey !== null) {
            res[linkKey].links.push(section)
          } else {
            res.push({
              fieldset: 'links',
              links: [ section ]
            })
            linkKey = res.length - 1
          }
          boxKey = null
          textKey = null
        }

        // the rest
        else {
          res.push(section)
          boxKey = null
          textKey = null
          linkKey = null
        }
      })
      return res
    }
  },
  methods: {
    key (...keys) {
      return keys.join('-')
    },

    // original sections
    isHeadline (section) {
      return section.fieldset === 'headline'
    },
    isCopy (section) {
      return section.fieldset === 'copy'
    },
    isImages (section) {
      return section.fieldset === 'images' && this.hasImages(section)
    },
    isBox (section) {
      return section.fieldset === 'box'
    },
    isLink (section) {
      return section.fieldset === 'link'
    },
    isVideo (section) {
      return section.fieldset === 'video'
    },

    // grouped sections
    isText (section) {
      return section.fieldset === 'text'
    },
    isBoxes (section) {
      return section.fieldset === 'boxes'
    },
    isLinks (section) {
      return section.fieldset === 'links'
    },

    hasImages (section) {
      return fn.isArray(section.images.value) && section.images.value.length > 0
    },
    imagesSize (section) {
      return section.display.value === 'slider' ? 'sections.banner' : 'sections.row'
    },
    sectionClass (section) {
      if (fn.has(section, 'cssclass') && fn.isString(section.cssclass.value)) {
        return section.cssclass.value
      }
    },
    subheadline (section) {
      if (fn.has(section, 'subheadline') && fn.isString(section.subheadline.value)) {
        return section.subheadline.value
      }
      return ''
    },
    imagesClass (section) {
      var res = this.sectionClass(section)
      if (section.display.value === 'slider') {
        res += ' is-slider'
      }
      return res
    }
  }
}
</script>

<style lang="sass">
.sections-elem
  .section
    margin-top: m(6)
    &:first-child
      margin-top: 0
    &.images
      .col
        padding: col-gap()
        background-color: $white-darker
      &.is-slider
        .col
          padding-left: 0
          padding-right: 0
          background-color: transparent
    &.boxes
      >.col
        padding: (col-gap() / 2) col-gap()
        background-color: $white-darker
    &.links
      .col
        ul
          display: flex
          justify-content: center
          flex-wrap: wrap
          li
            margin: m(1)
    &.video
      .col
        h2
          margin-bottom: m(2)

+md
  .sections-elem
    .section
      &.images
        .col
          padding: col-gap('md')
      &.boxes
        >.col
          padding: (col-gap('md') / 2) col-gap('md')
          background-color: $white-darker

+sm
  .sections-elem
    .section
      margin-top: m(3)
      &:first-child
        margin-top: 0
      &.images
        .col
          padding: col-gap('sm')
      &.boxes
        >.col
          padding: (col-gap('sm') / 2) col-gap('sm')
          background-color: $white-darker

+xs
  .sections-elem
    .section
      margin-top: m(2)
      &.images
        .col
          padding: col-gap('xs')
      &.boxes
        >.col
          padding: (col-gap('xs') / 2) col-gap('xs')
          background-color: $white-darker
</style>