<template>
  <div
    v-if="show"
    :class="{ 'animate': animate }"
    class="cookie-dialog-elem typo">
      <p v-html="message"></p>
      <div class="buttons">
        <link-elem :link="$store.state.site.pages.dataprotection.value">
          <button-elem class="button is-text">{{ $t('cookie.more') }}</button-elem>
        </link-elem>
        <button-elem class="button" @click="decline($event)">{{ $t('cookie.decline') }}</button-elem>
        <button-elem class="button is-primary" @click="ok($event)">{{ $t('cookie.ok') }}</button-elem>
      </div>
  </div>
</template>

<script>

export default {
  name: 'cookie-dialog-elem',
  props: {},
  data() {
    return {
      show: false,
      animate: false
    }
  },
  computed: {
    showConsentDialog () {
      return this.$store.getters['cookie/showConsentDialog']
    },
    message () {
      return this.$t('cookie.msg')
    }
  },
  methods: {
    toggleView (show) {
      if (show) {
        this.show = true
        window.setTimeout(() => {
          this.animate = true
        }, 2000)
      } else {
        this.animate = false
        window.setTimeout(() => {
          this.show = false
        }, 2000)
      }
    },
    decline (Event) {
      this.$store.dispatch('cookie/setConsent', false)
    },
    ok (Event) {
      this.$store.dispatch('cookie/setConsent', true)
      if (this.$analytics) {
        this.$analytics.setConsent(this.$route.fullPath)
      }
    }
  },
  watch: {
    showConsentDialog: {
      immediate: true,
      handler (show) {
        this.toggleView(show)
      }
    }
  }
}
</script>

<style lang="sass">
.cookie-dialog-elem
  position: fixed
  display: flex
  flex-direction: column
  align-items: center
  left: content-padding()
  right: content-padding()
  bottom: content-padding()
  padding: m(2)
  background-color: $grey-light
  transition: all .8s ease
  border-radius: $radius
  transform: translateY(120%)
  transition: all .8s ease
  z-index: $z-index-dialog
  p
    text-align: center
  .button
    margin: m(2) 3px 0 3px
  &.animate
    transform: none

+sm
  .cookie-dialog-elem
    left: 0
    right: 0
    bottom: 0
    border-radius: none
    width: 100%
</style>
