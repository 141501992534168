<template>
  <div class="navigation-elem" :class="displayClass" ref="nav">
    <div
      v-if="isOffCanvas"
      v-bind:class="{ 'is-open' : sliderIsOpen }"
      class="nav-toolbar"
      ref="navbar">
        <!-- for background of button and any other content -->
    </div>
    <nav
      class="nav-slider"
      :class="{ 'is-open' : sliderIsOpen }"
      @click="onClickSlider($event)"
      v-touch:swipe.right="onSwipeSlider">
        <div class="nav-viewbox">
          <links-elem
            :links="this.$store.state.navigation.navmeta"
            :maxLevel="1"
            @onclick="onClick"
            class="nav-meta"
            ref="meta">
          </links-elem>
          <links-elem
            :links="this.$store.state.navigation.navmain"
            :maxLevel="2"
            :dropdownStatus="dropdownStatus"
            :dropdownPosition="dropdownPosition"
            @onmouseenter="onMouseEnter"
            @onmouseleave="onMouseLeave"
            @onclick="onClick"
            class="nav-main"
            ref="main">
          </links-elem>
        </div>
    </nav>
    <div
      v-if="isOffCanvas"
      v-bind:class="{ 'is-open' : sliderIsOpen }"
      @click="onClickMenuButton($event)"
      class="nav-button"
      ref="button">
        <div class="icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
    </div>
  </div>
</template>

<script>
import dom from '@/services/DOM'
import { breakpoints } from '@/config/style.json'

export default {
  name: 'navigation-elem',
  data () {
    return {
      offCanvasBreakpoint: 'sm', // breakpoint, where off-canvas is always true
      navWidth: 0, // width of on-canvas navigation
      isOffCanvas: false,
      sliderIsOpen: false,
      currentUri: null,
      dropdownStatus: {},
      dropdownPosition: 'left', // TODO: set dynamically
      toolbarTop: 0,
      init: false
    }
  },
  created () {
    this.dropdownStatus = this.getDropdownsStatus(this.$store.state.navigation.navmain)
  },
  mounted () {

    // get breakpoint width, because we work with pixel, not with breakpoint-names
    if (fn.isString(this.offCanvasBreakpoint) && fn.has(breakpoints, this.offCanvasBreakpoint)) {
      this.offCanvasBreakpoint = breakpoints[this.offCanvasBreakpoint]
    }

    // for check, if navigation fit's in viewport, change or set to 0 to disable
    this.navWidth = this.$refs.main.getWidth() + this.$refs.meta.getWidth()

    // set off- or on-canvas classes
    this.setDisplayType(dom.getWindowWidth())

    // navigation is invisible up to this point
    this.$nextTick(() => {
      this.init = true
    })
  },
  computed: {
    displayClass () {
      var res = []
      if (this.isOffCanvas) {
        res.push('off-canvas')
        if (this.sliderIsOpen) {
          res.push('is-open')
        }
      } else {
        res.push('on-canvas')
        if (!this.init) {
          res.push('on-init')
        }
      }
      return res.join(' ')
    }
  },
  methods: {
    getDropdownsStatus (links) {
      var res = {}
      fn.each(links, (link) => {
        if (link.type === 'group') {
          res[link.id] = this.isOffCanvas && dom.isActiveUri(this.$route.path, link.uri)
          if (link.children && link.children.length > 0) {
            res = Object.assign(res, this.getDropdownsStatus(link.children))
          }
        }
      })
      return res
    },
    setDisplayType (windowWidth) {

      // off bei definition
      var isOffCanvas = windowWidth <= this.offCanvasBreakpoint

      // off because nav doesn't fit in viewbox
      if (!isOffCanvas && this.navWidth > 0) {
        isOffCanvas = dom.getWidth(this.$refs.nav) < this.navWidth
      }
      if (isOffCanvas !== this.isOffCanvas) {
        this.isOffCanvas = isOffCanvas
        this.sliderIsOpen = false
        this.$trigger('overlay/hide')
        this.dropdownStatus = this.getDropdownsStatus(this.$store.state.navigation.navmain)
      }
    },
    openSlider () {
      this.sliderIsOpen = true
      this.$trigger('overlay/show-slider', 'overlay/click')
    },
    closeSlider () {
      this.sliderIsOpen = false
      this.$trigger('overlay/hide')
    },
    setToolbarPosition (params) {
      if (!this.$refs.navbar) {
        return
      }
      var dim = dom.getDimensions(this.$refs.navbar)

      // start position from top, where navbar begins to hide
      var start = dim.height * 2
      var top = this.toolbarTop + params.diff
      if (start >= params.top || top < 0) {
        top = 0
      } else if (top > dim.height) {
        top = dim.height
      }
      if (top !== this.toolbarTop) {
        this.toolbarTop = top
        dom.setStyle(this.$refs.navbar, 'top', '-' + top + 'px')
        dom.setStyle(this.$refs.button, 'top', '-' + top + 'px')
      }
    },
    onClickMenuButton (Event) {
      Event.stopPropagation()
      if (this.sliderIsOpen) {
        this.closeSlider()
      } else {
        this.openSlider()
      }
    },
    openDropdown (link, closeOther) {
      if (closeOther) {
         fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = (id === link.id)
        })
      } else {
        this.dropdownStatus[link.id] = true
      }
    },
    closeDropdown (link) {
      if (!link) {
        fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = false
        })
      } else {
        this.dropdownStatus[link.id] = false
      }
    },
    toggleDropdown (link, closeOther) {
      if (this.dropdownStatus[link.id]) {
        this.closeDropdown(link)
      } else {
        this.openDropdown(link, closeOther)
      }
    },
    onMouseEnter (Event, link) {
      if (!this.$isTouch() && !this.isOffCanvas && link.type === 'group') {
        this.openDropdown(link)
      }
    },
    onMouseLeave (Event, link) {
      if (!this.$isTouch() && !this.isOffCanvas && link.type === 'group') {
        this.closeDropdown(link)
      }
    },
    onClick (Event, link) {

      // off-canvas
      Event.stopPropagation()
      if (this.isOffCanvas) {
        if (link.type === 'group') {
          this.toggleDropdown(link)
        } else if (link.type === 'scroll') {
          this.closeSlider()
        } else {
          this.closeSlider()
        }
      }
      
      // on-canvas
      else {
        if (link.type === 'group') {
          if (this.$isTouch()) {
            this.toggleDropdown(link, true)
          } else {
            this.closeDropdown()
            this.$router.push(link.redirect)
          }
        } else {
          this.closeDropdown()
        }
      }
    },
    onClickSlider (Event) {
      Event.stopPropagation()
    },
    onSwipeSlider () {
      this.closeSlider()
    },
    onWindowScroll (Event, params) {
      this.setToolbarPosition(params)
    },
    onWindowWidth (Event, width) {
      this.setDisplayType(width)
    }
  },
  events: {
    'overlay/click': 'closeSlider',
    'window/window-width': 'onWindowWidth',
    'window/scroll': 'onWindowScroll'
  }
}
</script>

<style lang="sass">

// on-canvas
$nav-main-color:                 $grey-dark
$nav-main-hover-color:           $link-active-color
$nav-main-active-color:          $link-active-color

$nav-meta-color:                 $grey
$nav-meta-hover-color:           $link-active-color
$nav-meta-active-color:          $link-active-color

// dropdowns on-canvas navigation
$nav-dropdown-color:             $grey-dark
$nav-dropdown-bg-color:          $grey-lighter
$nav-dropdown-hover-color:       darken($grey-lighter, 5%)

// for off-canvas slider
$nav-slider-bg-color:            $white-darker
$nav-slider-border-color:        $grey-lighter

$nav-slider-main-bg-color:       $white
$nav-slider-main-color:          $grey-dark
$nav-slider-main-hover-color:    $link-active-color
$nav-slider-main-active-color:   $black

$nav-slider-meta-bg-color:       $white-darker
$nav-slider-meta-color:          $grey
$nav-slider-meta-hover-color:    $link-active-color
$nav-slider-meta-active-color:   $grey-dark

// the off-canvas open button
$nav-toolbar-padding:            m(2)
$nav-button-bg-color:            $content-color
$nav-button-closed-color:        $grey // closed status
$nav-button-open-color:          $grey // open status

.navigation-elem
  &.on-init
    visibility: hidden
  &.on-canvas
    position: relative
    .nav-slider
      .nav-viewbox
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        .nav-meta
          &.links-elem-level-1
            display: flex
            align-items: center
            line-height: 1
            margin-left: - m(1)
            margin-right: - m(1)
            >.item
              display: flex
              flex-direction: column
              padding: 0 3px
              >.link
                position: relative
                display: block
                padding: 3px 0
                +font('small')
                color: $nav-meta-color
                white-space: nowrap
                &:hover
                  color: $nav-meta-hover-color
              &.is-active
                >.link
                  color: $nav-meta-active-color
              &:hover
                &.is-active
                  >.link
                    color: $nav-meta-active-color
              &.is-facebook,
              &.is-twitter,
              &.is-youtube
                >.link
                  font-size: 0
                  &:before
                    +font('default')
              &.is-facebook
                >.link
                  +icon('facebook')
              &.is-twitter
                >.link
                  +icon('twitter')
              &.is-youtube
                >.link
                  +icon('youtube')
        .nav-main
          &.links-elem-level-1
            position: relative
            display: flex
            line-height: 1
            margin-left: - m(1.5)
            margin-right: - m(1.5)
            >.item
              display: flex
              flex-direction: column
              padding: 0 m(1.5)
              border-right: 1px solid $nav-main-color
              &:last-child
                border-right: none
              >.link
                display: flex
                align-items: center
                position: relative
                height: 100%
                padding-top: 3px
                color: $nav-main-color
                text-transform: uppercase
                white-space: nowrap
                &:hover
                  color: $nav-main-hover-color
                &:before // optional icon
                  padding-right: m(1)
                  +font('medium')
              &:after
                display: block
                align-self: center
                content: ""
                margin-top: 2px
                height: 2px
                width: 0px
                transition: width .2s ease
                background-color: $nav-main-color
              &.is-active
                >.link
                  color: $nav-main-active-color
                &:after
                  width: 100%
                  background-color: $nav-main-active-color
              &:hover
                &.is-active
                  >.link
                    color: $nav-main-active-color
                  &:after
                    background-color: $nav-main-active-color
              &.is-home
                >.link
                  font-size: 0
                  +icon('home')
                  &:before
                    padding-right: 0
                &.is-active
                  >.link
                    color: $yellow
                  &:after
                    background-color: transparent
              &.is-events
                >.link
                  +icon('calendar')
              &.has-dropdown
                .links-elem-level-2
                  display: flex
                  visibility: hidden
                  position: absolute
                  flex-direction: column
                  top: 100%
                  padding: m(1) 0
                  background-color: $nav-dropdown-bg-color
                  z-index: $z-index-base + 10
                  +soft-shadow
                  &:before
                    position: absolute
                    top: -5px
                    content: ""
                    border-bottom: 5px solid $nav-dropdown-bg-color
                    border-left: 5px solid transparent
                    border-right: 5px solid transparent
                  &.is-left
                    margin-left: 0
                    &:before
                      left: m(4)
                  &.is-right
                    align-self: flex-end
                    margin-right: - m(1)
                    &:before
                      right: m(4)
                  >.item
                    padding: 0
                    >.link
                      display: flex
                      padding: m(1) m(2)
                      color: $nav-dropdown-color
                      white-space: nowrap
                      &:hover
                        color: $nav-dropdown-color
                        background-color: $nav-dropdown-hover-color
                    &.is-request
                      >.link
                        +icon('form')
                        &:before
                          padding-right: 3px
                    &.is-active
                      >.link
                        background-color: $nav-dropdown-hover-color
              &.is-open // dropdown is open
                &.is-active
                  &:after
                    background-color: transparent
                .links-elem-level-2
                  visibility: visible

  &.off-canvas
    .nav-toolbar
      position: fixed
      top: 0
      right: 0
      width: 100%
      height: $nav-toolbar-height
      padding: 0
      background-color: $nav-button-bg-color
      border-bottom: 1px solid $border-color
      z-index: $z-index-slider - 20 // - 10 has overlay
      cursor: pointer
    .nav-slider
      visibility: hidden
      position: fixed
      top: 0
      right: 0
      height: 100%
      width: auto
      max-width: 100%
      padding: $nav-toolbar-height 0 0 0
      background-color: $nav-slider-bg-color
      z-index: $z-index-slider + 10
      transform: translateX(100%)
      transition: transform .5s ease 0s, visibility 0s linear .5s
      +soft-shadow
      .nav-viewbox
        display: flex
        flex-direction: column
        height: 100%
        overflow-x: hidden
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
        .item
          .link
            display: block
            position: relative
            padding: m(.5) m(4)
            white-space: nowrap
            text-overflow: ellipsis
            cursor: pointer
          &.has-dropdown
            >.link
              display: flex
              flex-direction: row-reverse
              justify-content: flex-end
              align-items: center
              +icon('down')
              &:before
                margin-left: 4px
                font-size: .6em
            .links-elem-level-2
              visibility: hidden
              height: 0
              .item
                .link
                  padding-left: m(6)
            &.is-open
              .links-elem-level-2
                visibility: visible
                height: 100%
        .nav-meta
          order: 2
          padding-top: m(2)
          padding-bottom: m(2)
          background-color: $nav-slider-meta-bg-color
          .item
            .link
              color: $nav-slider-meta-color
              +font('small')
              padding-top: m(.4)
              padding-bottom: m(.4)
              &:hover
                color: $nav-slider-meta-hover-color
            &.is-exact-active
              .link
                color: $nav-slider-meta-active-color
        .nav-main
          order: 1
          padding-top: m(2)
          padding-bottom: m(2)
          background-color: $nav-slider-main-bg-color
          border-top: 1px solid $nav-slider-border-color
          border-bottom: 1px solid $nav-slider-border-color
          .item
            .link
              color: $nav-slider-main-color
              &:hover
                color: $nav-slider-main-hover-color
            >.link
              &:before
                transform: rotate(-90deg)
                transition: transform .1s linear
            &.is-open
              >.link
                &:before
                  transform: translateY(2px) rotate(0deg)
            &.is-active
              >.link
                color: $nav-slider-main-active-color
              &.is-open
                >.link
                  color: $nav-slider-main-color
      &.is-open
        visibility: visible
        transform: translateX(0)
        transition: transform .5s ease 0s
    .nav-button
      position: fixed
      right: 0
      top: 0
      height: $nav-toolbar-height
      cursor: pointer
      z-index: $z-index-slider + 20
      .icon
        position: absolute
        top: $nav-toolbar-padding
        right: content-padding('sm')
        width: $nav-toolbar-height - ($nav-toolbar-padding * 2)
        height: $nav-toolbar-height - ($nav-toolbar-padding * 2)
        span
          position: absolute
          display: block
          background-color: $nav-button-closed-color
          height: 2px
          width: 100%
          border-radius: 2px
          &:nth-child(1)
            top: 12%
            transition: top .1s linear, height 0s linear
            transition-delay: .2s
          &:nth-child(2)
            top: calc(50% - 2px)
            transition: transform .2s linear
          &:nth-child(3)
            top: calc(50% - 2px)
            transition: transform .2s linear
          &:nth-child(4)
            top: calc(88% - 4px)
            transition: top .1s linear, height 0s linear
            transition-delay: .2s
      &.is-open
        .icon
          span
            background-color: $nav-button-open-color
            &:nth-child(1)
              height: 0
              top: calc(50% - 2px)
              transition-delay: 0s
            &:nth-child(2)
              transform: rotate(45deg)
            &:nth-child(3)
              transform: rotate(-45deg)
            &:nth-child(4)
              height: 0
              top: calc(50% - 2px)
              transition-delay: 0s

+xs
  .navigation-elem
    &.off-canvas
      .nav-slider
        min-width: 75%
      .nav-button
        .icon
          //right: content-padding('xs')
</style>