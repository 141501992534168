<template>
  <svg
    class="svg-elem"
    :viewBox="viewBox"
    :style="style"
    :class="name"
    role="img">
      <title v-if="$slots.default"><slot></slot></title>
      <use :xlink:href="href"></use>
  </svg>
</template>

<script>
import dom from '@/services/DOM'

export default {
  name: 'svg-elem',
  props: {
    name: {
      type: String,
      default: ''
    },
    color: { // set color like #234abc direclty instead with class
      type: String,
      default: ''
    }
  },
  computed: {
    href () {
      return '#' + this.name
    },
    viewBox () {
      return dom.getViewBox(this.name)
    },
    style () {
      if (fn.isString(this.color) && this.color.substr(0, 1) === '#') {
        return 'fill:' + this.color + ';'
      }
      return null
    }
  }
}
</script>

<style lang="sass">

  // every color-class or fill is ignored, when svg has fill-attribute
  // (which is intended to have multi-colored svgs)
  .svg-elem
    height: auto
    fill-rule: evenodd
    clip-rule: evenodd
    stroke-linejoin: round
    stroke-miterlimit: 1.41421
    fill: $grey
    &.is-primary
      fill: $primary-color
    &.is-seconday
      fill: $seconday-color
    &.is-warning
      fill: $warning-color
    &.is-success
      fill: $success-color
    &.is-white
      fill: $white
</style>
