<template>
  <div
    class="text-elem">
      <template v-for="(block, i) in paragraphs">
        <component
          :is="htmlBlock(block)"
          :key="i"
          :class="block.cssclass">
        </component>
      </template>
  </div>
</template>

<script>
export default {
  name: 'text-elem',

  /**
   * Text may be
   * - Object (see below)
   * - a single copy field 
   * - a single string field
   * - other field types (not implemented yet)
   * - a string
   * - an array with all elements mixed
   */
  props: [
    'text'
  ],
  computed: {

    // Convert given fields to flat array of html-blocks
    paragraphs () {
      var res = []
      var text = fn.isArray(this.text) ? this.text : [ this.text ]
      fn.each(text, (section) => {

        // simply a string
        if (fn.isString(section)) {
          res.push({
            tag: 'p',
            html: section
          })
          return
        }

        // a field
        if (fn.isObject(section) && fn.has(section, 'value') && fn.has(section, 'type')) {
          switch (section.type) {
            case 'string':
              res.push({
                tag: 'p',
                html: section.value
              })
              break
            case 'html':
              res = res.concat(section.value)
              break
          }
          return
        }

        // object with fields an additional data for css-classes
        // {
        //  headline: [field],
        //  subheadline: [field],
        //  copy: [field],
        //  cssclass: [field],
        //  isteaser: [field]
        // }
        var cssClass = []
        if (fn.has(section, 'cssclass') && fn.isString(section.cssclass.value)) {
          cssClass.push(section.cssclass.value)
        }
        if (fn.has(section, 'headline') && fn.isString(section.headline.value)) {
          res.push({
            tag: 'h1',
            html: section.headline.value,
            cssclass: cssClass.join(' ')
          })
        }
        
        if (fn.has(section, 'subheadline') && fn.isString(section.subheadline.value)) {
          res.push({
            tag: 'h2',
            html: section.subheadline.value,
            cssclass: cssClass.join(' ')
          })
        }

        if (fn.has(section, 'copy')) {
          if (fn.has(section, 'isteaser') && fn.isTrue(section.isteaser.value)) {
            cssClass.push('is-teaser')
          }
          if (fn.isArray(section.copy.value)) {
            fn.each(section.copy.value, (block) => {
              block.cssclass = cssClass.join(' ')
              res.push(block)
            })
          }
        }
      })
      return res
    }
  },
  methods: {
     htmlBlock (block) {
      if (block.tag === 'hr') {
        return { template: '<hr />' }
      }
      if (!block.html) { // just to be sure
        return { template: '' }
      }
      let regex = /^<code>.*<\/code>$/i
      if (fn.isString(block.html) && regex.test(block.html)) {
        return { template: '<pre>' + block.html + '</pre>' }
      }
      return { template: '<' + block.tag + '>' + block.html + '</' + block.tag + '>' }
    }
  }
}
</script>

<style lang="sass">
</style>