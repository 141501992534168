import Vue from 'vue'

const loggerService = class {

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  info (mixed, maxDepth, title) {
    if (fn.parseBool(process.env.VUE_APP_LOGGER)) {
      if (!fn.isInteger(maxDepth) || maxDepth <= 0) {
        maxDepth = 0 // unlimited
      }
      if (fn.isIterable(mixed)) {
        title = fn.isString(title) ? '%c' + title + '%c ' : ''
        this._loop(mixed, maxDepth, 0, title)
      } else {
        this._print(mixed)
      }
    }
  }

  log (mixed, maxDepth, title) {
    if (!fn.isIterable(mixed)) {
      mixed = '### ' + mixed
    }
    window.info(mixed, maxDepth, title)
  }

  // error is always printed, regarless of logging settings
  error (errno, errmsg) {
    errno = errno || 1000
    errmsg = errmsg || 'an unknown error occured'
    this._print(errmsg + ' (Error ' + errno + ')', 'error')
  }

  /*
  |--------------------------------------------------------------------------
  | Private Methods
  |--------------------------------------------------------------------------
  */

  _loop (obj, maxDepth, currentDepth, key) {
    var indent = '  '.repeat(currentDepth)
    this._bracket(obj, key, indent, true)
    if (maxDepth === 0 || currentDepth <= maxDepth) {
      fn.each(obj, (node, key) => {
        key = '%c' + key + '%c'
        var indent = '  '.repeat(currentDepth + 1)
        if (node instanceof Vue) {
          this._print(indent + key + ': Vue Component ' + node.$options.name)
        } else if (fn.isFunction(node)) {
          this._print(indent + key + ': ' + node.name + '() {...}')
        } else if (fn.isIterable(node)) {
          this._loop(node, maxDepth, currentDepth + 1, key + ': ')
        } else if (fn.isString(node)) {
          let string = node.split("\n").shift()
          let pad = parseInt(process.env.VUE_APP_CONSOLE_WIDTH) - indent.length - key.length - 2
          this._print(indent + key + ': ' + fn.truncate(string, pad))
        } else {
          this._print(indent + key + ': ' + node)
        }
      })
    }
    this._bracket(obj, key, indent, false)
  }

  _bracket (obj, key, indent, start) {
    var name, length, open, close
    if (fn.isObject(obj)) {
      name = 'obj'
      length = Object.keys(obj).length
      open = '{'
      close = '}'
    }
    if (fn.isArray(obj)) {
      name = 'arr'
      length = obj.length
      open = '['
      close = ']'
    }
    if (fn.isMap(obj)) {
      name = 'map'
      length = obj.size
      open = '{'
      close = '}'
    }
    if (start) {
      if (length > 0) {
        close = ''
      }
      this._print(indent + key + name +'(' + length + ') ' + open + close)
    } else if (length > 0) {
      this._print(indent + close)
    }
  }

  /**
   * @param {str} str the log message
   * @param {str} level the console function (log|warn|error)
   */
  _print (str, level) {
    if (!level) {
      level = 'log'
    }
    if (fn.isString(str) && str.indexOf('%c') >= 0) {
      // eslint-disable-next-line
      console[level](str, 'font-weight: bold', 'font-weight: normal')
    } else {
      // eslint-disable-next-line
      console[level](str)
    }
  }
}

// register globally, syntax needed this way!
const logger = new loggerService()
window.info = (mixed, maxDepth, title) => {
  logger.info(mixed, maxDepth, title)
}
window.log = (mixed, maxDepth, title) => {
  logger.log(mixed, maxDepth, title)
}
window.error = (errno, errmsg) => {
  logger.error(errno, errmsg)
}