<template>
  <footer class="segment footer-segment" ref="footer">
    <div class="content">
      <div class="row main">
        <div class="col is-4 contact">
          <div class="row">
            <div class="col">
              <text-elem :text="[
                  $store.state.site.content.company,
                  $store.state.site.content.address
                ]"
                class="address"></text-elem>
            </div>
            <div class="col">
              <text-elem :text="[
                  'Tel: ' + $store.state.site.content.telephone.value,
                  'Fax: ' + $store.state.site.content.fax.value
                ]"
                class="phone"></text-elem>
              <link-elem :link="$store.state.site.content.email"></link-elem>
            </div>
            <div class="col">
              <text-elem :text="[
                  $store.state.site.content.contactinfo
                ]"
                class="info"></text-elem>
            </div>
          </div>
        </div>
        <div class="col is-8 map">
          <div id="map"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-segment',
  mounted () {
    this.$store.commit('registerElement', {
      name: 'footer',
      elem: this.$refs.footer
    })

    // https://docs.mapbox.com/help/demos/how-mapbox-works/how-geocoding-works.html
    // https://docs.mapbox.com/mapbox-gl-js/api/
    mapboxgl.accessToken = this.$store.state.mapbox.apiKey
    var map = new mapboxgl.Map({
      container: 'map',
      style: this.$store.state.mapbox.style,
      center: [this.$store.state.mapbox.long, this.$store.state.mapbox.lat],
      zoom: this.$store.state.mapbox.zoom
    })
    map.on('load', () => {
      fn.each(map.getStyle().layers, (layer) => {
        if(layer.id.substr(-6) === '-label') {
          map.setLayoutProperty(layer.id, 'text-field', ['get', 'name_' + this.$store.state.mapbox.locale])
        }
      })
    })
   
    var marker = new mapboxgl.Marker({
      draggable: false
    }).setLngLat([
      this.$store.state.mapbox.long,
      this.$store.state.mapbox.lat
    ]).addTo(map)
  }
}
</script>

<style lang="sass">
.footer-segment
  z-index: $z-index-base
  background-color: $white-darker
  margin-top: m(3)
  .content
    background-color: $white-darker
    .main
      .contact
        .row
          display: block
          .col
            white-space: nowrap
            .address
              p:first-child
                +font('bold')
      .map
        #map
          width: 100%
          height: 100%

+sm
  .footer-segment
    .main
      display: block
      .contact
        .row
          display: flex
          flex-wrap: wrap
      .map
        #map
          height: 240px

+xs
  .footer-segment
    .main
      .map
        margin-left: - content-padding('xs')
        margin-right: - content-padding('xs')
        padding-left: 0
        padding-right: 0
</style>