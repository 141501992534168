<template>
  <footer class="segment footer-nav-segment">
    <div class="content">
      <div class="row">
        <div class="col">
          <links-elem
            :links="this.$store.state.navigation.navfooter"
            :maxLevel="1"
            class="nav-footer">
          </links-elem>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-nav-segment'
}
</script>

<style lang="sass">
.footer-nav-segment
  .content
    padding-bottom: m(3)
    border-top: 1px solid $border-color
    .nav-footer
      display: flex
      +font('small')
      li
        margin-right: m(2)
        &:last-child
          margin-right: 0

+xs
  .footer-nav-segment
    .nav-footer
      display: block
</style>