<template>
  <div
    class="image-zoom-elem"
    :class="[ colorClass, singleClass ]">
      <div class="menu">
        <transition name="fade" mode="out-in">
          <p :key="currentImage" class="caption typo is-soft">
            {{ caption }}
          </p>
        </transition>
        <button-elem
          icon="close"
          class="is-big is-icon is-narrow"
          :class="buttonColorClass"
          @click="close">
        </button-elem>
      </div>
      <div class="canvas">
        <transition :name="transitionName" mode="out-in">
          <div
            class="wrapper"
            :key="image.filename"
            v-touch:swipe.left="gotoNextImage"
            v-touch:swipe.right="gotoPrevImage">
              <image-elem
                :file="image"
                size="zoom.fullsize">
              </image-elem>
          </div>
        </transition>
        <div class="nav-image prev">
          <button-elem
            icon="left"
            class="is-big is-icon"
            :class="[buttonPrevImageClass, buttonColorClass]"
            @click="gotoPrevImage()">
          </button-elem>
        </div>
        <div class="nav-image next">
          <button-elem
            icon="right"
            class="is-big is-icon"
            :class="[buttonNextImageClass, buttonColorClass]"
            @click="gotoNextImage()">
          </button-elem>
        </div>
      </div>
      <div class="thumbs" ref="thumbsContainer">
        <div
          v-if="hasSectionNavigation"
          class="nav-thumbs prev">
            <button-elem
              icon="left"
              class="is-icon is-narrow"
              :class="[buttonPrevPageClass, buttonColorClass]"
              @click="gotoPrevPage()">
            </button-elem>
        </div>
        <carousel
          :perPage="rangecount"
          :paginationEnabled="false"
          :navigationEnabled="true"
          :centerMode="true"
          :navigateTo="currentPage"
          :mouseDrag="false"
          :style="carouselStyle"
          class="sideshow-elem-carousel">
            <slide
              v-for="(file, i) in files"
              :key="i"
              :class="{ 'is-current': (i === currentImage) }"
              @click.native="onClickThumb($event, i)">
                <div
                  class="frame"
                  :style="thumbFrameStyle">
                    <image-elem
                      :file="file"
                      :size="thumbConfig(file)">
                    </image-elem>
                    <div class="marker is-softer"></div>
                </div>
            </slide>
        </carousel>
        <div
          v-if="hasSectionNavigation"
          class="nav-thumbs next">
            <button-elem
              icon="right"
              class="is-icon is-narrow"
              :class="[buttonNextPageClass, buttonColorClass]"
              @click="gotoNextPage()">
            </button-elem>
        </div>
      </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'image-zoom-elem',
  props: {

    //The list of images
    files: {
      type: Array
    },

    // Index of the initial image to show
    index: {
      type: Number,
      default: 0
    },

    // The color-scheme black | grey | white
    color: {
      type: String,
      default: 'black'
    }
  },
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      currentImage: -1,
      abscount: null,
      rangecount: null,
      pagecount: null,
      currentPage: 0,
      transitionName: 'slide-left',
      thumbSize: 64,
      thumbPadding: 1,
      lastWindowWidth: 0
    }
  },
  computed: {
    image () {
      return this.files[this.currentImage]
    },
    hasPrevImage () {
      return this.currentImage > 0
    },
    hasNextImage () {
      return (this.currentImage + 1) < this.abscount
    },
    hasSectionNavigation () {
      return this.abscount > this.rangecount
    },
    hasPrevPage () {
      return this.currentPage > 0
    },
    hasNextPage () {
      return this.currentPage < Math.floor((this.abscount - 1) / this.rangecount)
    },
    caption () {
      if (fn.has(this.files[this.currentImage].content, 'title') && fn.isString(this.files[this.currentImage].content.title.value)) {
        return this.files[this.currentImage].content.title.value
      }
      return null
    },
    colorClass () {
      return 'is-' + this.color
    },
    singleClass () {
      return this.abscount === 1 ? 'is-single' : ''
    },
    buttonColorClass () {
      if (this.color === 'black') {
        return 'is-white'
      }
      return null
    },
    buttonPrevImageClass () {
      return this.hasPrevImage ? '' : 'is-disabled'
    },
    buttonNextImageClass () {
      return this.hasNextImage ? '' : 'is-disabled'
    },
    buttonPrevPageClass () {
      return this.hasPrevPage ? '' : 'is-disabled'
    },
    buttonNextPageClass () {
      return this.hasNextPage ? '' : 'is-disabled'
    },
    carouselStyle () {
      let frame = this.thumbSize + (this.thumbPadding * 2)
      return 'width:' + frame * this.rangecount + 'px'
    },
    thumbFrameStyle () {
      return 'width:' + this.thumbSize + 'px;' +
        'padding-right:' + this.thumbPadding + 'px;' +
        'padding-left:' + this.thumbPadding + 'px;'
    }
  },
  methods: {
    open (index) {
      if (index >= 0 && this.files[index] !== undefined) {
        this.selectImage(index, false)
        document.addEventListener('keyup', this.keyControl, true)
      } else {
        this.close()
      }
    },
    close () {
      this.currentImage = -1
      document.removeEventListener('keyup', this.keyControl, true)
      this.$emit('close')
    },
    selectImage (index, updatePage) {
      this.transitionName = this.currentImage < index ? 'slide-left' : 'slide-right'
      this.currentImage = index
      if (updatePage) {
        this.gotoCurrentPage()
      }
    },
    onClickThumb (Event, i) {
      Event.stopPropagation()
      this.selectImage(i, false)
    },
    gotoPrevImage () {
      if (this.hasPrevImage) {
        this.selectImage(this.currentImage - 1, true)
      }
    },
    gotoNextImage () {
      if (this.hasNextImage) {
        this.selectImage(this.currentImage + 1, true)
      }
    },
    gotoPrevPage () {
      if (this.hasPrevPage) {
        this.currentPage--
      }
    },
    gotoNextPage () {
      if (this.hasNextPage) {
        this.currentPage++
      }
    },
    gotoCurrentPage () {
      let page = fn.round(this.currentImage / this.rangecount, 0, 'down')
      if (this.currentPage !== page) {
        this.currentPage = page
      }
    },

    // fixed size, file is given for future setttings like crop-config
    thumbConfig (file) {
      return [ this.thumbSize, this.thumbSize, true ]
    },
    keyControl (event) {
      switch (event.which) {
        case 27:
          this.close()
          break
        case 37:
          this.gotoPrevImage()
          break
        case 39:
          this.gotoNextImage()
          break
      }
    },

    // only update carousel, when the window changed for 10px
    onWindowWidthChange (Event, windowWidth) {
      let diff = this.lastWindowWidth - windowWidth
      if (diff >= 10 || diff <= -10) {
        this.lastWindowWidth = windowWidth
        this.init()
        this.gotoCurrentPage()
      }
    },
    init () {
      this.abscount = this.files.length
      if (this.$refs.thumbsContainer === undefined) {
        return
      }
      let frame = this.thumbSize + (this.thumbPadding * 2)
      let space = this.$refs.thumbsContainer.clientWidth -
        (2 * frame) // extra space for buttons and padding
      let thumbs = fn.round(space / frame, 0, 'down')
      if (thumbs > this.abscount) {
        thumbs = this.abscount
      }
      this.rangecount = (thumbs < 1) ? 1 : thumbs
      this.pagecount = fn.round(this.abscount / this.rangecount, 0, 'up')
    }
  },
  watch: {
    index: {
      immediate: true,
      handler(newVal, oldVal) {
        this.open(newVal)
      }
    }
  },
  events: {
    'window/window-width': 'onWindowWidthChange'
  },
  mounted() {
    this.init()
    this.gotoCurrentPage()
  }
}
</script>

<style lang="sass">
.image-zoom-elem
  position: fixed
  display: flex
  flex-direction: column
  top: 0
  right: 0
  bottom: 0
  left: 0
  overflow: hidden
  z-index: $z-index-overlay + 1
  .menu
    flex-shrink: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%
    padding: m(2) m(2)
    z-index: $z-index-overlay + 3
    .caption
      margin: 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      transition: opacity .4s ease-out
      opacity: 1
      &.fade-enter
        opacity: 0
        transition: opacity .4s ease-in .3s
      &.fade-leave-active
        opacity: 0
        transition: opacity .4s ease-in .3s
  .canvas
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: 0 m(2) m(2) m(2)
    z-index: $z-index-overlay + 2
    .wrapper
      position: relative
      flex-grow: 1
      transition: transform .5s ease-out, opacity .3s ease-out
      &.slide-left-enter, // moving to right
      &.slide-right-leave-active
        opacity: 0
        transform: translate(10%, 0)
        transition: transform .5s ease-in, opacity .3s ease-in .2s
      &.slide-left-leave-active, // moving to left
      &.slide-right-enter
        opacity: 0
        transform: translate(-10%, 0)
        transition: transform .5s ease-in, opacity .3s ease-in .2s
      img
        position: absolute
        top: 50%
        bottom: 0
        left: 50%
        right: 0
        max-width: 100%
        max-height: 100%
        transform: translate(-50%,-50%)
    .nav-image
      position: absolute
      top: 0
      height: 100%
      width: 40%
      z-index: $z-index-overlay + 3
      .button
        height: 100%
        width: 100%
      &.prev
        left: m(2)
        .button
          justify-content: flex-start
      &.next
        right: m(2)
        .button
          justify-content: flex-end
  .thumbs
    flex-shrink: 1
    display: flex
    flex-direction: row
    justify-content: center
    padding: m(2)
    z-index: $z-index-overlay + 3
    .sideshow-elem-carousel
      .VueCarousel-wrapper
        .VueCarousel-inner
          .VueCarousel-slide
            .frame
              display: flex
              flex-direction: column
              // width and padding are set by style
              cursor: pointer
            .marker
              height: 3px
              margin-top: 2px
              background-color: transparent
            &.is-current
              .frame
                cursor: default
                .marker
                  background-color: $white
      .VueCarousel-pagination,
      .VueCarousel-navigation
        display: none !important
    .nav-thumbs
      flex-shrink: 1
      display: flex
      flex-direction: column
      justify-content: center
  &.is-single
    .canvas
      .nav-image
        display: none
    .thumbs
      display: none
  &.is-black
    background-color: $black
    .menu
      .caption
        color: $white
  &.is-grey
    background-color: $grey-lighter
    .menu
      .caption
        color: $text-color
  &.is-white
    background-color: $white
    .menu
      .caption
        color: $text-color

+xs
  .image-zoom-elem
    .canvas
      .nav
        display: none
    .thumbs
      display: none
</style>
