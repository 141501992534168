<template>
  <div
    class="template default-template"
    v-bind:class="[ pageClass, scrollClass, scrollDirectionClass ]">
      <header-segment></header-segment>
      <div class="segment content-segment">
        <transition name="fade" mode="out-in">
          <component
            class="content"
            :is="currentPage"
            :key="$store.state.pagekey"
            ref="content">
          </component>
        </transition>
      </div>
      <footer-segment></footer-segment>
      <footer-nav-segment></footer-nav-segment>
      <overlay-segment></overlay-segment>
      <cookie-dialog-elem></cookie-dialog-elem>
  </div>
</template>

<script>

// eslint-disable-next-line
import dom from '@/services/DOM'
import styles from '@/styles/main.sass'
import Loader from '@/services/Loader'
import HeaderSegment from '@/components/segments/Header'
import OverlaySegment from '@/components/segments/Overlay'
import FooterSegment from '@/components/segments/Footer'
import FooterNavSegment from '@/components/segments/FooterNav'
import CookieDialogElem from '@/components/elements/CookieDialog'

export default {
  name: 'default-template',
  components: {
    HeaderSegment,
    OverlaySegment,
    FooterSegment,
    FooterNavSegment,
    CookieDialogElem
  },
  data () {
    return {
      isScrolled: false,
      scrollDirection: false
    }
  },
  computed: {
    currentPage () {
      return Loader.getPage()
    },
    showDebugSegment () {
      return fn.parseBool(process.env.VUE_APP_WINDOW_INFO)
    },
    pageClass () {
      return 'page-'// + this.$route.name.replace('/', '-') // template!
    },
    scrollClass () {
      return this.isScrolled ? 'is-scrolled' : ''
    },
    scrollDirectionClass () {
      if(this.isScrolled) {
        return 'scroll-' + this.scrollDirection
      }
      return ''
    }
  },
  methods: {
    onWindowScroll (Event, params) {
      this.scrollDirection = params.direction
      this.isScrolled = params.scrolled
    }
  },
  events: {
    'window/scroll': 'onWindowScroll'
  }
}
</script>

<style lang="sass">
.default-template
  .content-segment
    .content
      padding-top: m(4)
      padding-bottom: m(4)
      transition: all .4s cubic-bezier(.55,0,.1,1)
      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity .5s ease
      &.fade-enter,
      &.fade-leave-active
        opacity: 0
</style>
