<template>
  <div class="boxes-elem" :class="boxesClass">
    <div class="row">
      <div
        v-for="(box, i) in boxesConverted"
        :key="i"
        :class="boxClass()"
        class="box col">
          <div class="row">
            <div
              v-if="hasImages(box)"
              class="col is-6 image">
                <images-elem
                  :files="box.images.value"
                  display="slider"
                  size="sections.box"
                  :zoom="box.zoom.value"
                  :autoplay="box.autoplay.value"
                  :speed="box.speed.value">
                </images-elem>
            </div>
            <div class="col text">
              <link-elem
                v-if="hasLink(box)"
                :link="box.link">
                  <text-elem
                    :text="box">
                  </text-elem>
              </link-elem>
              <text-elem
                v-else
                :text="box">
              </text-elem>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'boxes-elem',
  props: {
    boxes: {}
  },
  computed: {
    boxesConverted () {
      return fn.isArray(this.boxes) ? this.boxes : [ this.boxes ]
    },
    boxesClass () {
      return 'has-' + this.boxesConverted.length
    }
  },
  methods: {
    hasSubheadline (box) {
      // conditional field is not delivered, when hasSubheadline is false
      return fn.has(box, 'subheadline') && fn.isString(box.subheadline.value)
    },
    hasImages (box) {
      return fn.isArray(box.images.value) && box.images.value.length > 0
    },
    hasLink (box) {
      return fn.has(box, 'link') && box.link.type.value !== 'none'
    },
    boxClass () {
      if (this.boxesConverted.length > 0) {
        return 'is-' + (12 / this.boxesConverted.length)
      }
    }
  }
}
</script>

<style lang="sass">
.boxes-elem
  .image
    padding-top: 0
    padding-bottom: 0
  .text
    padding-top: 0
    padding-bottom: 0
    .text-elem
      padding-top: m(1)
      h2
        display: flex
        align-items: center
        padding: 0
    .link
      .text-elem
        h2
          +icon('link', 'after')
          &:after
            +font('small')
            margin-left: m(1)
  &.is-small-text
    .text
      .text-elem
        h2
          +font('default', 'bold')
          text-transform: uppercase
        p,
        li
          +font('small')
  &.has-1
    .image
       flex-basis: 33.33%
    .text
      flex-basis: 66.66%
      .text-elem
        padding-top: 0
  &.has-2
    .image
       flex-basis: 40%
    .text
      flex-basis: 60%
      .text-elem
        padding-top: 0
  &.has-3
    .row
      .box
        .row
          flex-direction: column

+sm
  .boxes-elem
    &.has-2
      .row
        flex-wrap: wrap
        .box
          flex-basis: 50%
          .row
            flex-direction: column
            .col
              &.text
                .text-elem
                  padding-top: m(1)

+until(720px)
  .boxes-elem
    &.has-2,
    &.has-3
      .row
        flex-wrap: wrap
        .box
          flex-basis: 100%
          .row
            flex-direction: row
            .col
              &.image
                flex-basis: 33.33%
              &.text
                flex-basis: 66.66%
                .text-elem
                  padding-top: 0

+xs
  .boxes-elem
    .text-elem
      padding-top: m(1) !important

</style>